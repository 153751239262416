.booking-layout {
  display: flex;
  justify-content: center;
  margin: 66px 20px 30px;
}

.booking-confirmation {
  padding: 30px;
  flex-direction: column;
  
  &-inner {
    max-width: 450px;
    margin: 0 auto;

    .flex-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .color-indicator {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        margin-right: 10px;
        background-color: rgb(130, 71, 245);
        border-radius: 20px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    p {
      font-size: 14px;
    }
  }
}

.booking-container {
  display: flex;
  flex: 1 1 auto;
  max-width: 800px;
  min-height: 550px;
  transition: all 0.22s ease-out;

  &.visible-hours {
    max-width: 1060px;

    @media screen and (min-width: 1000px) {
      min-width: 900px;
    }
  }

  &-header {
    text-align: center;
  }

  width: 95%;
  border: 1px solid rgba(26, 26, 26, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);

  .left-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 300px;
    border-right: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
    transition: all 0.22s ease-out;
    padding: 55px 20px 20px;

    @media screen and (max-width: 1000px) {
      width: 100% !important;
      border-bottom: 1px solid rgba(26, 26, 26, 0.1);
      border-right: none;
    }

    h3 {
      margin: 15px 0 25px;
    }

    .go-back-btn {
      width: 44px;
      height: 44px;
      border-radius: 40px;
      border: 1px solid #0060d4;
      cursor: pointer;
      margin-bottom: 20px;
    }

    &.visible-hours {
      width: 35%;
    }

    .time-period {
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
        font-size: 15px;
      }
    }
  }

  .calendar-side {
    flex: 1 1 50%;
    width: 50%;
    transition: all 0.22s ease-out;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    &-inner {
      position: relative;
      padding: 28px 20px 20px;

      .form-group {
        display: flex;
        flex-direction: column;
        width: 80%;

        @media screen and (max-width: 649px) {
          width: 100%;
        }

        input, textarea {
          border-radius: 8px;
          min-height: 46px;
          border: 1px solid black;
          padding: 4px 10px;
        }
      }

      &-form {
        h3 {
          margin: 20px 0;
        }

        label {
          font-size: 14px;
          color: black;
        }

        button {
          padding: 5px 15px;
          background: #0060d4;
          color: white;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (min-width: 650px) and (max-width: 999px) {
    flex-direction: column;
  }

  @media screen and (max-width: 649px) {
    display: block;
  }
}

.divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin: 15px 0;
}