.appointment-calendar {
  display: flex;
  flex: 1 1 300px;
  width: 100%;

  @media screen and (max-width: 649px) {
    display: block;
  }
}

.calendar {
  display: block;
  position: relative;
  height: auto;
  margin-top: 22px;
  padding: 0 19px;
  width: 100%;

  &.visible-hours {
    width: 60%;

    @media screen and (max-width: 649px) {
      width: 100%;
    }
  }  

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .column{
      &.col-center {
        min-width: 200px;
      }
    }

    .current-date {
      width: 200px;
    }

    .next-prev-month {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FEFEFF;
      box-sizing: border-box;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.005);
      border-radius: 9px;
      cursor: pointer;

      &:hover {
        background: #d9e9ff;        
        color: #0060d4;
        border-radius: 50%;
      }
    }
  }

  .days {
    text-transform: uppercase;
    margin: 20px 0 15px;
  }

  .row {
    // border-bottom: 1px solid lightgray;
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: none;

      .column {
        &:first-child {
          border-bottom-left-radius: 15px;
        }

        &:last-child {
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .column {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);

    &.col-center {
      text-align: center;      
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: black;
    }
  }

  .body {
    background: #FFFFFF;

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;      
      background: white;
      transition: 0.25s ease-out;
      position: relative;
      
      div {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.selected {
        div {          
          background-color: #0060e6 !important;
          color: #eef5ff !important;
        }
      }
      
      &.working-day {
        cursor: pointer;

        &.available-day {
          div {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eef5ff;
            border-radius: 50%;
            color: #0060e6;
    
            &:hover {
              background: #d9e9ff;
              transition: 0.5s ease-out;
              color: #0060d4;
            }
          }
        }        
      }
      
      &.holiday {
        div {
          color: gray;
        }
      }

      &:last-child {
        border-right: none;
      }

      &.disabled {        
        div {
          color: #C4C4C4;
          pointer-events: none;
        }
      }

      .number {
        font-family: Source Sans Pro;        
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;        
      }      
    }

    .today {
      div {        
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: white;
      }
    }

    .disabled {        
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }

    .today-indicator {
      width: 5px;
      height: 5px;
      background: #0060e6;
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
    }
  }

  @media screen and (min-width: 901px) {
    .body {
      .row {
        .column {
          &.has-event {
            &.today {
              div {
                width: 44px;
                height: 44px;
              }
            }

            div {
              width: 44px;
              height: 44px;
            }
          }
          &.today {
            div {
              width: 44px;
              height: 44px;
            }
          }          
        }
      }
    }
  }
  
  @media screen and (max-width: 900px) {
    .body {
      .row {
        .column {
          &.has-event {
            &.today {
              div {
                width: 44px;
                height: 44px;
              }
            }

            div {
              width: 44px;
              height: 44px;
            }
          }
          &.today {
            div {
              width: 44px;
              height: 44px;
            }
          }          
        }
      }
    }
  }
}

.hours-list {
  width: 40%;
  padding: 0 15px 30px;
  margin-top: 22px;

  @media screen and (max-width: 649px) {
    width: 100%;
  }

  .selected-date {
    margin: 9px 0 20px;
  }

  .available-hours {
    max-height: 65vh;
    overflow-y: auto;
    padding-right: 15px;

    @media screen and (max-width: 649px) {
      max-height: 30vh;
    }

    .hour-item {
      margin-bottom: 10px;
      display: flex;
      gap: 10px;

      &.two-btns {
        button {
          width: 50%;
        }
      }

      button {
        border-radius: 8px;
      }

      .indicator-btn {
        width: 100%;
        height: 52px;
        background-color: #fff;
        border: 1px solid #0060e6;        
        color: #0060e6;
        cursor: pointer;

        &:hover {
          border: 2px solid #0060e6;
        }
      }

      .hour-btn {
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
      }

      .confirm-btn {
        height: 52px;
        background-color: #0060e6;
        border: 1px solid #0060e6;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}